import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid/Grid"
import GalleryGrid from '../../components/grid/schema/GalleryGrid'

import "./galerie.styl"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      alloy {
        galleries {
          _id
          title
          slug
          cover {
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const { galleries } = data.alloy
  
  return (
    <div id="gallery-list" className="main-page">
      <h1 className="page-title">Fotogalerien</h1>

      <Grid
        items={galleries.filter(({title}) => title)}
        render={gallery => <GalleryGrid {...gallery} />}
      />
    </div>
  )
}
