import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default ({ title, slug, cover, entryPrefix = "galerie" }) => (
  <Link to={`/${entryPrefix}/${slug}`} id="grid-gallery">
    <div className="grid-content">
      {cover.image && <Img fluid={cover.image.childImageSharp.fluid} className="grid-image"/>}
      <div className="grid-title">{title}</div>
    </div>
  </Link>
)
